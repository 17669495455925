
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { EventBuss } from '@/types/global'

import {
    computed,
    defineComponent,
    Emitter,
    inject,
    LoginData,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    ResponseData,
    UserInfo,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { demoLogin, login } from '@/api'
import ImageVerify from '@/components/dialog/dialog-image-verification.vue'
import { AppMutation } from '@/store/types'
import { AxiosResponse } from 'axios'
import { SvgArrowDown1 } from '@/components/icons'

export default defineComponent({
    components: {
        ImageVerify,
        'svg-arrow-down': SvgArrowDown1
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const { t, locale } = useI18n()
        const store = useStore()
        const router = useRouter()
        const errorMessage = ref<string>('')
        const pwd = ref<string>('')
        const uname = ref<string>('')
        const showVerify = ref<boolean>()

        const languages = ref<{ code: string; name: string }[]>([
            { code: 'kor', name: '한국어' },
            { code: 'zh-CN', name: '简体中文' },
            { code: 'zh-HK', name: '繁體中文' },
            { code: 'en', name: 'ENGLISH' },
            { code: 'th', name: 'แบบไทย' },
            { code: 'ja', name: '日本語' },
            { code: 'vn', name: 'Tiếng Việt' }
        ])

        const showLangOptions = ref(false)

        const action = ref(0)

        onBeforeMount(() => {
            // emitter.on(EventBuss.WINDOW_RESIZED, hideVerify)
        })

        onBeforeUnmount(() => {
            // emitter.off(EventBuss.WINDOW_RESIZED, hideVerify)
        })

        const hideVerify = () => {
            showVerify.value = false
        }

        const doLogin = () => {
            showVerify.value = false

            if (action.value == 0) {
                const loginModel = {
                    userName: uname.value,
                    password: pwd.value
                }
                errorMessage.value = ''
                store.commit(AppMutation.SHOW_LOADING)
                login(loginModel)
                    .then((response) => {
                        handleLoginResponse(response, false)
                    })
                    .catch((e) => {
                        console.log(e)
                        store.commit(AppMutation.HIDE_LOADING)
                        if (deviceOrientation.value === 'portrait') {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                        } else {
                            errorMessage.value = t('prompt.networkerror')
                        }
                    })
            } else {
                store.commit(AppMutation.SHOW_LOADING)
                demoLogin()
                    .then((response) => {
                        handleLoginResponse(response, true)
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)

                        if (deviceOrientation.value === 'portrait') {
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                        } else {
                            errorMessage.value = t('prompt.networkerror')
                        }
                    })
            }

            // store.commit(AppMutation.INIT_USER_SESSION, {
            //     token: 'sdfsdfsdfe',
            //     username: 'jays',
            //     notice: 'Hellow world',
            //     point: 30023,
            //     memberId: 1,
            //     isFree: true,
            //     commandType: 1
            // })

            // router.push({ name: ROUTES.LOBY })
        }

        const handleLoginResponse = (
            response: AxiosResponse,
            isFree: boolean
        ) => {
            store.commit(AppMutation.HIDE_LOADING)
            const reponseData = response.data as ResponseData<LoginData>
            if (reponseData.status === 200) {
                const { token, name, amount, id, code } =
                    reponseData.data!.userInfo

                let username = ''

                if (!name || name === '') username = code
                else username = name

                store.commit(AppMutation.INIT_USER_SESSION, {
                    token,
                    username,
                    point: amount,
                    memberId: id,
                    isFree: isFree
                })

                emitter.emit(EventBuss.SOCKET_CONNECT)
                emitter.emit(EventBuss.START_CLIENT_TIME_OUT)
                router.push({ name: ROUTES.LOBY })
            } else {
                // failed

                if (deviceOrientation.value === 'portrait') {
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        reponseData.description!
                    )
                } else {
                    errorMessage.value = reponseData.description!
                }
            }
        }

        const setLanguage = (e: { code: string; name: string }) => {
            showLangOptions.value = false
            locale.value = e.code
            store.commit(AppMutation.SET_LANG, e.code)
        }

        const veryFyHumanLogin = () => {
            // if (document.activeElement != document.body) {
            //     const focusedElement = document.activeElement as HTMLElement
            //     focusedElement.blur()
            // }
            showVerify.value = true
        }

        const showLanguage = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'lang'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
            showLangOptions.value = false
        }

        const showAgreeBox = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'agree'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const doTrylogin = () => {
            action.value = 1
            veryFyHumanLogin()
        }

        const accountLogin = () => {
            action.value = 0
            veryFyHumanLogin()
        }

        const onImageVerifySuccess = () => {
            showVerify.value = false
            doLogin()
        }

        const lang = computed((): string => store.getters['lang'])

        const loginEnabled = computed(
            () => pwd.value.length > 0 && uname.value.length > 0
        )
        const selectedLanguage = computed(
            (): { code: string; name: string } | undefined => {
                return languages.value.find((x) => x.code === lang.value)
            }
        )

        const deviceOrientation = computed<'landscape' | 'portrait'>(
            () => store.getters['deviceOrientation']
        )

        watch(
            () => deviceOrientation.value,
            (_new, _old) => {
                if (_new !== _old) {
                    hideVerify()
                }
            }
        )

        return {
            errorMessage,
            pwd,
            uname,
            lang,
            loginEnabled,
            showVerify,
            languages,
            selectedLanguage,
            showLangOptions,
            showLanguage,
            doLogin,
            t,
            showAgreeBox,
            doTrylogin,
            accountLogin,
            onImageVerifySuccess,
            setLanguage
        }
    }
})
