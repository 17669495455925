
import { useStore } from '@/store'
import { getDeviceOrientation } from '@/utils'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import SlideVerify from 'vue3-slide-verify'

import 'vue3-slide-verify/dist/style.css'

export default defineComponent({
    emits: ['update:modelValue', 'verify-success'],
    props: {
        modelValue: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        SlideVerify
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const store = useStore()
        const imgs = ref<string[]>([])
        const dialogEl1 = ref<HTMLElement>()
        const dialogEl2 = ref<HTMLElement>()
        const w = ref(410)
        const h = ref(183)
        const l = ref(48)
        const r = ref(15)

        const componentKey1 = ref(new Date().getTime())
        const componentKey2 = ref(new Date().getTime())

        const showSlideImages = ref(false)

        const close = () => {
            emit('update:modelValue', false)
        }

        const onSuccess = () => {
            emit('verify-success')
        }

        onMounted(() => {
            let arrImages: string[] = []
            if (screenfullOrientation.value === 'portrait') {
                arrImages = [require('@/assets/imgs/verify-1.jpg')]
            } else {
                arrImages = [require('@/assets/imgs/pc/verify1@2x.png')]
            }

            imgs.value = [...arrImages]
            showSlideImages.value = true

            setTimeout(() => {
                let elem: DOMRect | undefined

                if (getDeviceOrientation() === 'landscape') {
                    elem = dialogEl1.value?.getBoundingClientRect()
                    if (elem) {
                        w.value = elem.width * 0.96
                        h.value = w.value * 0.445
                    }
                } else {
                    elem = dialogEl2.value?.getBoundingClientRect()
                    if (elem) {
                        w.value = elem.width * 0.96
                        h.value = w.value * 0.62
                    }
                }

                l.value = h.value * 0.16
                r.value = l.value / 4
                componentKey1.value = new Date().getTime()
                componentKey2.value = new Date().getTime()
            }, 100)
        })

        const screenfullOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )

        return {
            componentKey1,
            componentKey2,
            l,
            h,
            w,
            r,
            imgs,
            showSlideImages,
            dialogEl1,
            dialogEl2,
            t,
            close,
            onSuccess
        }
    }
})
